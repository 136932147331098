<template>
  <section class="app-container">
    <Filter
        @search="handleSearch"
        @refresh-table="handleResetTable"
        @refresh-table-height="handleResetTableHeight"
    />
    <SeatApprovalList ref="seatApprovalListRef"></SeatApprovalList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "@/views/home/Seat/SeatApproval/components/Filter.vue";
import SeatApprovalList from "@/views/home/Seat/SeatApproval/components/SeatApprovalList.vue";

export default defineComponent({
  name: "seatApproval",
  components: {
    SeatApprovalList,
    Filter
  },
  setup() {
    const seatApprovalListRef = ref();
    const handleSearch = (searchParams) => {
      seatApprovalListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      seatApprovalListRef.value.doSearch();
    };
    const handleResetTableHeight = () => {
      seatApprovalListRef.value.getSize();
    };

    return {
      seatApprovalListRef,
      handleSearch,
      handleResetTable,
      handleResetTableHeight
    };
  },
});
</script>
