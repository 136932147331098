<template>
  <div ref="seatApprovalRef">
    <a-modal
        :getContainer="() => $refs.seatApprovalRef"
        class="seatApplicationModal"
        :visible="visible"
        @cancel="closeModal"
        width="62.5vw"
        :zIndex="1000"
        :title="'申请编号：'+ formState.id"
        centered
    >
    <div class="receiptStatus" :style="{  'left': `${formState.id?.length * 0.5 + 10}vw`}">
      <a-tag :class="`status ${seatUserNameStatus(formState.status)}`">
          {{seatUserNameStatus(formState.status)}}
        </a-tag>
    </div>
      <template #footer>
        <a-space>
          <a-button @click="closeModal" type="primary" ghost v-show="!['0','6'].includes(formState.status)">返回</a-button>
          <a-button @click="handelReject" danger ghost v-show="['0','6'].includes(formState.status)">驳回</a-button>
          <a-button @click="handelApprove" type="primary" v-show="['0','6'].includes(formState.status)">通过</a-button>
        </a-space>
      </template>
      <div>
        <div>
          <CardTitle title="基础信息"/>
          <div class="infos">
            <a-row class="info">
              <a-col :span="3"><span class="indo-lable">项目组：</span></a-col>
              <a-col :span="21"><span class="indo-lable" style="color: #4D4D4D;font-weight: 600">{{formState.engagementDescription}}</span></a-col>
              <a-col :span="3"><span class="indo-lable">申请人：</span></a-col>
              
              <a-col :span="10">
                <template v-if="!canModify">
                  <span class="indo-lable" style="color: #4D4D4D"> <span style="color: #1890FF;font-weight: 600">{{formData.fullName}}</span>{{' ('}}{{formData.email}}{{')'}}</span>
                </template>
                <template v-else>
                  <div v-if="changeAppealPeople == false"  style="display: flex;align-items: center;">
                    <span class="indo-lable" style="color: #4D4D4D"> <span style="color: #1890FF;font-weight: 600">{{formData.fullName}}</span>{{' ('}}{{formData.email}}{{')'}}</span>
                    <div style="cursor:pointer;margin-left:0.9vw;position: relative;top:1px" @click="changeAppealPeopleHandler(formData.fullName)"><FormOutlined :style="{display:'block'}" /></div>
                  </div>
                  <div v-if="changeAppealPeople == true">
                    <a-select
                      ref="appealPeopleRef"
                      v-model:value="appealPeopleValue"
                      show-search
                      placeholder="input search text"
                      style="width: 200px"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handleSearchSelect"
                      @select="(val) => handleChangeSelect(val,'appeal')"
                      @blur="() => handlerBlurSelect('appeal')"
                    >
                    <a-select-option v-for="item in appealPeopleData" :key="item.id" :value="item.id">
                      {{ item.fullName }}
                    </a-select-option>
                    </a-select>
                  </div>
                </template>
              </a-col>
              
              <a-col :span="2"><span class="indo-lable">提交时间：</span></a-col>
              <a-col :span="9"><span class="indo-lable" style="color: #4D4D4D">{{formData.operationTime}}</span></a-col>
              <a-col :span="3"><span class="indo-lable">Group Lead：</span></a-col>
              
              <a-col :span="10">
                <template v-if="!canModify">
                  <span class="indo-lable" style="color: #4D4D4D"><span style="color: #1890FF;font-weight: 600">{{formData.groupLeanFullName}}</span>{{' ('}}{{formData.groupLeanEmail}}{{')'}}</span>
                </template>
                <template v-else>
                  <div v-if="changeGroupPeople == false" style="display: flex;align-items: center;">
                    <span class="indo-lable" style="color: #4D4D4D"><span style="color: #1890FF;font-weight: 600">{{formData.groupLeanFullName}}</span>{{' ('}}{{formData.groupLeanEmail}}{{')'}}</span>
                    <div style="cursor:pointer;margin-left:0.9vw;position: relative;top:1px" @click="changeGroupPeopleHandler(formData.groupLeanFullName)"><FormOutlined :style="{display:'block'}" /></div>
                  </div>
                  <div v-if="changeGroupPeople == true">
                    <a-select
                      ref="groupPeopleRef"
                      v-model:value="groupPeopleValue"
                      show-search
                      placeholder="input search text"
                      style="width: 200px"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      @search="handleGroup"
                      @select="(val) => handleChangeSelect(val,'group')"
                      @blur="() => handlerBlurSelect('group')"
                    >
                    <a-select-option v-for="item in groupPeopleData" :key="item.id" :value="item.id">
                      {{ item.fullName }}
                    </a-select-option>
                    </a-select>
                  </div>
                </template>
              </a-col>

              <a-col :span="2"><span class="indo-lable">使用时间：</span></a-col>
              <a-col :span="9"><span class="indo-lable" style="color: #4D4D4D">{{formData.startDate}}{{' 至 '}}{{formData.endDate}}</span></a-col>
              <a-col :span="3"><span class="indo-lable">Office：</span></a-col>
              <a-col :span="21"><span class="indo-lable" style="color: #4D4D4D">{{formData.office}}</span></a-col>
              <a-col :span="3"><span class="indo-lable">座位位置：</span></a-col>
              <a-col :span="10">
                <div v-if="formData.seatApplicationUserCommandList.length > 0" class="seat-box">
                  <img src="@/assets/images/xindiCenter56.png" width="60" class="seat-box-img" @click="checkSeatInfo" v-if="formData.floor === 'floor01'">
                  <img src="@/assets/images/wanke05.png" width="120" height="40" class="seat-box-img" @click="checkSeatInfo" v-else-if="formData.floor === 'floor04'">
                  <img src="@/assets/images/wanke04.png" width="120" height="40" class="seat-box-img" @click="checkSeatInfo" v-else-if="formData.floor === 'floor03'">
                  <img src="@/assets/images/wanke03.png" width="120" height="40" class="seat-box-img" @click="checkSeatInfo" v-else-if="formData.floor === 'floor02'">
                </div>
              </a-col>
              <a-col :span="2"><span class="indo-lable"><div v-if="formData.fileId">审批文件：</div></span></a-col>
              <a-col :span="9">
                <div v-if="formData.fileId" class="indo-lable-file">
                  <span>
                    <FileExclamationOutlined class="icon"/>
                    <span style="color: #4D4D4D;margin-left: 0.5vw">{{formData.fileName}}</span>
                  </span>
                  <span style="margin-right: 2vw" @click="downloadClick"><DownloadOutlined class="icon"/></span>
                </div>
              </a-col>
              <a-col :span="3"><span class="indo-lable">备注：</span></a-col>
              <a-col :span="21">
                <Tooltip :title="formData.reason" placement="bottomLeft">
                  <span class="indo-lable-reason">{{formData.reason}}</span>
                </Tooltip>
              </a-col>
            </a-row>
          </div>
        </div>
        <div>
          <CardTitle title="座位申请详情"/>
          <div class="seat-code">
            <div v-for="(item,index) in formData.seatApplicationUserCommandList" :key="index" class="seat-code-info">
              <span class="indo-lable">座位编号：</span><span class="indo-lable" style="color: #4D4D4D">{{item.seatCode}}</span>
              <span class="indo-lable" style="margin-left: 1vw">使用人：</span>
              <span class="indo-lable" style="color: #4D4D4D"><span style="color: #1890FF;font-weight: 600">{{item.userFullName}}</span>
                <span v-show="item.userFullName !== 'TBD' && item.userFullName">{{' ('}}{{item.userEmail}}{{')'}}</span>
              </span>
            </div>
          </div>
        </div>
        <div v-if="formData.seatReviewHistoryCommandList.length > 0 && !['0','6'].includes(formState.status)">
          <CardTitle title="审批记录"/>
          <div v-for="(item,index) in formData.seatReviewHistoryCommandList" :key="index" class="review-history">
            <div :style="`color: ${item.status === '1'? '#58B99E': '#DC5E4F'}`">
              <span style="font-size: 1vw">●</span>
              <span class="status">{{item.status === '1'? '审核已通过' : '审批被驳回'}}</span>
              <span style="margin-left:1vw;font-weight:0.8rem;color:black" v-if="item.updateStatus && item.updateEnd"><span v-if="item.status !== '1'">驳回内容：</span>修改使用时间为{{ item.updateStatus }} 至 {{ item.updateEnd }}</span>
            </div>
            <div class="segment">|</div>
            <div class="history-info">
              <TeamOutlined class="icon" />
              <span style="color: #3182CE"><span style="font-weight: 600;margin-left: 0.4vw">{{item.approverFullName}}</span>{{' ('}}{{item.approverEmail}}{{')'}}</span>
              <span class="segment-icon">|</span>
              <img src="@/assets/images/ScheduleOndutytime.png" class="icon img"/>
              <span style="margin-left: 0.4vw;color: #4D4D4D99;">{{item.operationTime}}</span>
            </div>
            <div class="history-info" style="color: #4D4D4D99;">{{item.reason}}</div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
        :getContainer="() => $refs.seatApprovalRef"
        :visible="rejectVisible"
        @cancel="closeRejectModal"
        @ok="submitRejectModal"
        width="30vw"
        centered
        title="填写驳回原因"
    >
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closeRejectModal">取消</a-button>
          <a-button @click="submitRejectModal" type="primary">确定</a-button>
        </div>
      </template>
      <a-form
          layout="vertical"
          :model="rejectFormData"
          :rules="rejectFormRules"
          ref="rejectFormRef"
      >
        <a-form-item
            name="rejectReason"
            label="驳回原因"
        >
          <a-textarea
              v-model:value="rejectFormData.rejectReason"
              placeholder="请输入"
              :rows="5"
              showCount
              :maxlength="70"
              :auto-size="{ minRows: 3, maxRows: 3 }"
              @keyup.enter="(e) => { e.stopPropagation() }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <CheckSeatPositions
        :visible="seatPositionsVisible"
        :selectedData="formData.seatApplicationUserCommandList"
        :formState="formData"
        @close-modal="closeSeatPositions"
    ></CheckSeatPositions>
    <ModalBox v-model="modelValue" :ok="handleOk" :cancel="handleCancel" :content="content" :showIconCom="showIconCom" />
  </div>
</template>

<script>
import CardTitle from "@/components/CardTitle.vue";
import {computed, ref, watch, nextTick} from "vue";
import {message} from "ant-design-vue";
import {DownloadOutlined, FileExclamationOutlined, TeamOutlined, FormOutlined } from "@ant-design/icons-vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import Tooltip from "@/components/Tooltip.vue";
import CheckSeatPositions from "@/views/home/Seat/components/CheckSeatPositions.vue";
import {downloadFile} from "@/utils/common";
import {seatUserNameStatus} from "@/utils/formatStatus";
import { debounce } from "lodash";
import ModalBox from '@/components/ModalBox'
export default {
  name: "SeatApprovalDetail",
  emits: ["close-modal", "save-modal"],
  methods: {seatUserNameStatus},
  components: {
    CheckSeatPositions, 
    FileExclamationOutlined, 
    Tooltip, 
    DownloadOutlined, 
    TeamOutlined, 
    CardTitle,
    ModalBox,
    FormOutlined
  },
  props: {
    visible: Boolean,
    detailData: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  setup(props,{ emit }) {
    const { $api } = useGlobalPropertyHook();

    const formState = computed(() => props.detailData)
    
    // 权限 Sit Manage 并且单据状态不为 已过期、已失效 允许修改申请人和Group Lead
    const canModify = computed(()=>{ return formState.value.roleName === 'Sit Manager' && !['3','4'].includes(formState.value.status)})

    const formData = ref({
      seatApplicationUserCommandList: [],
      seatReviewHistoryCommandList: [],
    })

    const getDetailsInfo = () => {
      console.log('3',formState.value.id)
      $api.getApplicationInfo({id:formState.value.id}).then((res) => {
        formData.value = res.data;
      });
    }

    const downloadClick = () => {
      $api.downloadSeatFile(formData.value.fileId).then((res) => {
        downloadFile(res.file, formData.value.fileName);
      });
    }

    const seatPositionsVisible = ref(false)
    const checkSeatInfo = () => {
      seatPositionsVisible.value = true
    }
    const closeSeatPositions = () => {
      seatPositionsVisible.value = false
    }

    const closeModal = () => {
      emit("close-modal");
    };

    const handelReject = () => {
      rejectVisible.value = true
    };

    const handelApprove = () => {
      $api.seatPermissions({applicationId: formState.value.id}).then((res) => {
        if(res.code === 200){
          message.success(res.msg);
          emit("save-modal");
        }
      });
    };

    const rejectVisible = ref(false)
    const rejectFormData = ref({
      rejectReason: ''
    })
    const rejectFormRef = ref();

    const validatorSpace = async (rule, value) => {
      if (value && value.replace(/\s/g, "").length === 0) {
        return Promise.reject("不能只输入空格");
      } else {
        return Promise.resolve();
      }
    };

    const rejectFormRules = {
      rejectReason: [
        {
          required: true,
          message: "请填写驳回原因",
          trigger: "blur"
        },
        {
          validator: validatorSpace,
          message: "不能只输入空格",
          trigger: "blur"
        }
      ]
    };

    const closeRejectModal = () => {
      rejectFormRef.value.resetFields();
      rejectVisible.value = false
    };
    const submitRejectModal = () => {
      rejectFormRef.value.validate().then(() => {
        const params = {
          applicationId: formState.value.id,
          rejectReason: rejectFormData.value.rejectReason
        }
        $api.rejectSeatApplication(params).then((res) => {
          if(res.code === 200){
            message.success(res.msg);
            rejectFormRef.value.resetFields();
            rejectVisible.value = false
            emit("save-modal");
          }
        });
      });
    };

    watch(
        () => props.visible,
        (val) => {
          changeAppealPeople.value = false
          changeGroupPeople.value = false
          if(val) {
            getDetailsInfo()
          } else {
            formData.value = {}
            formData.value.seatApplicationUserCommandList = []
            formData.value.seatReviewHistoryCommandList = []
          }
        }
    )

    let changeAppealPeople = ref(false)
    let changeGroupPeople = ref(false)
    let appealPeopleValue = ref()
    let groupPeopleValue = ref()
    let appealPeopleRef = ref(null)
    let groupPeopleRef = ref(null)

    const changeAppealPeopleHandler = (value) => {
      appealPeopleValue.value = value
      changeAppealPeople.value = true
      appealPeopleData.value = []
      nextTick(()=>{
        // 聚焦，注意需要nextTick中不然可能这个组件实例还是空
        appealPeopleRef.value.focus()
      })
    }

    const changeGroupPeopleHandler = (value) => {
      groupPeopleValue.value = value
      changeGroupPeople.value = true
      groupPeopleData.value = []
      nextTick(()=>{
        // 聚焦，注意需要nextTick中不然可能这个组件实例还是空
        groupPeopleRef.value.focus()
      })
    }



    let appealPeopleData = ref([])
    let groupPeopleData = ref([])
    let modelValue = ref(false)
    let showIconCom = ref('')
    const content = ref('')
    let isManualBlur = false
    let changeType = 0
    let changeParams = {}

    const handleChangeSelect = (value,type) => {
      changeType = type === "appeal" ? '0' : '1'
      changeParams.applicantId = value
      isManualBlur = true
      if(type === "appeal"){
        appealPeopleRef.value.focus()
        content.value = '修改后，原申请人将失去操作权限，申请单操作将转移至新申请人，是否继续？'
      } else {
        groupPeopleRef.value.focus()
        content.value = '修改后，座位使用情况统计将按照新Group Lead更新数据，是否继续？'
      }
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true
    }

    const handleSearchSelect = debounce((value) => {
       searchAppealPerson(value,'appeal')
    },300)

    const handleGroup = debounce((value) => {
       searchAppealPerson(value,"group")
    },300)

    const searchAppealPerson = async (value,type) => {
      if(type == "appeal"){
        let result = await $api.myApplycationChangeAppealPerson({command:value})
        appealPeopleData.value = result.data
      } else {
        let result = await $api.myApplycationChangeGroupLead({command:value})
        groupPeopleData.value = result.data
      }
    
    }

    const handleCancel = () => {
      isManualBlur = false
    }

    const handleOk = async() => {
      isManualBlur = false
      changeParams.id = formState.value.id
      changeParams.state = formState.value.status
      changeParams.type = changeType
      await $api.seatModalchangeInfo(changeParams)
      changeParams = {}
      emit("save-modal");
    }


    const handlerBlurSelect = (type) => {
      if(isManualBlur == false && type == 'appeal'){
        appealPeopleData.value = []
        changeAppealPeople.value = false
      } else if(isManualBlur == false && type == 'group') {
        groupPeopleData.value = []
        changeGroupPeople.value = false
      }
    }

    return {
      formState,
      formData,
      closeModal,
      getDetailsInfo,
      handelApprove,
      handelReject,
      rejectVisible,
      closeRejectModal,
      submitRejectModal,
      rejectFormData,
      rejectFormRef,
      rejectFormRules,
      downloadClick,
      checkSeatInfo,
      seatPositionsVisible,
      closeSeatPositions,
      canModify,
      changeAppealPeople,
      changeGroupPeople,
      changeAppealPeopleHandler,
      changeGroupPeopleHandler,
      appealPeopleValue,
      appealPeopleData,
      handleChangeSelect,
      handleSearchSelect,
      handlerBlurSelect,
      appealPeopleRef,
      modelValue,
      content,
      showIconCom,
      handleOk,
      handleCancel,
      groupPeopleRef,
      groupPeopleValue,
      groupPeopleData,
      handleGroup
    }
  }
}
</script>

<style scoped lang="less">
.seatApplicationModal{
  position: relative;
  .infos {
    margin: 1.25vw 0;
    background: #FCFCFC;
    .info {
      padding: 16px;
      .indo-lable {
        font-size: 16px;
        color:#4D4D4D99;
        font-weight: 400;
      }
      .indo-lable-file {
        font-size: 16px;
        color:#4D4D4D99;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
      }
      .indo-lable-reason {
        font-size: 16px;
        color: #4D4D4D;
        font-weight: 400;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
      .icon{
        color: #3182CE;
        font-size: 1vw;
        cursor: pointer;
      }
      .seat-box {
        .seat-box-img {
          cursor: pointer;
        }
      }
    }
  }
  .receiptStatus {
    position: absolute;
    top:17px;
    .status{
    font-size: 0.729vw;
    padding: 0.1vw 0.86vw;
    border: 0;

    &.待审批, &.改期待审批 {
      color: #1890FF;
      background: #DBEEFF;
    }
    &.已通过 {
      color: #58B99E;
      background: #DDF8F0 60%;
    }
    &.已过期 {
      color: #999999;
      background: #CCCCCC 60%;
    }
    &.已取消 {
      color: #999999;
      background: #CCCCCC 60%;
    }
    &.已驳回 {
      color: #DC5E4F;
      background: #FFE7EC;
    }
  }
  }

  .seat-code {
    margin: 1.25vw 0;
    background: #FCFCFC;
    max-height: 7.5vw;
    overflow-y: auto;

    .seat-code-info {
      padding: 0.4vw 0.8vw 0.2vw 0.8vw;

      .indo-lable {
        font-size: 16px;
        color:#4D4D4D99;
        font-weight: 400;
      }
    }
  }

  .review-history{
    position: relative;
    margin-top: 1.25vw;

    .status {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin-left: 0.8vw;
    }
    .segment {
      font-size: 2.8vw;
      color: #EBEBEB;
      position: absolute;
      top: 0.5vw;
      left: -0.05vw;
    }
    .history-info{
      margin-left: 1.4vw;
      margin-top: 0.3vw;
      font-size: 0.79vw;
    }
    .segment-icon {
      margin: 0 1vw;
      color: #3182CE;
    }

    .img {
      width: 1vw;
      height: 0.9vw;
      cursor: pointer;
      margin-top: -0.2vw;
    }
    .icon {
      color: #3182CE;
    }
  }
}
:deep(.ant-col){
  margin-bottom: 10px;
}
:deep(.ant-col-3) {
  max-width: 9.5%;
}
:deep(.ant-modal-body){
  max-height: 80vh;
  overflow-y: auto;
}
:deep(.ant-modal-footer) {
  .ant-btn-dangerous:not(.ant-btn-sm){
    color: #ff4d4f !important;
    border-color: #ff4d4f !important;
  }
}
</style>
