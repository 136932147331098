<template>
  <section class="org-list">
    <a-table
        ref="seatApprovalListRef"
        :columns="columns"
        :data-source="seatApprovalList"
        rowKey="id"
        :scroll="{
          x: 1200,
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }"
        :pagination="pagination"
    >
      <template #status="{ record }">
        <a-tag :class="`status ${seatUserNameStatus(record.status)}`">
          {{seatUserNameStatus(record.status)}}
        </a-tag>
      </template>
      <template #userName="{ text }">
        <a-popover placement="bottom">
          <template #content>
            {{text}}
          </template>
          <span>
            <span>{{text ? text.split(";")[0]+';' : null}}</span>
            <span v-if="text? text.includes(';') : false" class="userName">+{{text.split(";").length - 1}}...</span>
          </span>
        </a-popover>
      </template>
      <template #usagetime="{ record }">
        {{record.startDate}}{{' 至 '}}{{record.endDate}}
      </template>
      <template #action="{ record }">
        <ActionBtn
            v-bind="record"
            :btnList="btnList"
            @EyeOutlined="handleDetails(record)"
        />
      </template>
    </a-table>
    <SeatApprovalDetail
        :visible="detailVisible"
        :detailData="detailData"
        @close-modal="closeSaveModal"
        @save-modal="submitDetailModal"
    ></SeatApprovalDetail>
  </section>
</template>

<script>
import {useGlobalPropertyHook} from "@/hooks/common";
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue";
import {useTableHeight} from "@/utils/common";
import ActionBtn from "@/components/ActionBtn.vue";
import {seatUserNameStatus} from "@/utils/formatStatus";
import SeatApprovalDetail from "@/views/home/Seat/SeatApproval/components/SeatApprovalDetail.vue";
import { useStore } from "vuex";

const btnList = [
  {
    name: '详情',
    icon: 'EyeOutlined',
    auth: ['system:my:seatApproval:details']
  }
]
export default {
  name: "SeatApprovalList",
  methods: {seatUserNameStatus},
  components: {SeatApprovalDetail, ActionBtn},
  setup() {
    const { $api } = useGlobalPropertyHook();

    const seatApprovalListRef = ref();
    const columns = [
      {
        title: "申请单号",
        dataIndex: "id",
        key: "id",
        ellipsis: true,
        width: 150,
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        width: 120,
        slots: { customRender: "status" },
      },
      {
        title: "Group Lead",
        dataIndex: "groupLeanNameEmail",
        key: "groupLeanNameEmail",
        ellipsis: true,
        width: 180,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split(";")[0] : null}</div>
              <div>{text ? text.split(";")[1] : null}</div>
            </div>
        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "申请人",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 100,
      },
      {
        title: "提交时间",
        dataIndex: "operationTime",
        key: "operationTime",
        ellipsis: true,
        width: 150,
      },
      {
        title: "座位使用人",
        dataIndex: "userName",
        key: "userName",
        ellipsis: true,
        width: 180,
        slots: { customRender: "userName" },
      },
      {
        title: "使用时间",
        dataIndex: "usagetime",
        key: "usagetime",
        ellipsis: true,
        width: 200,
        slots: { customRender: "usagetime" },
      },
      {
        title: "City",
        dataIndex: "cityName",
        key: "cityName",
        ellipsis: true,
        width: 70,
      },
      {
        title: "Building",
        dataIndex: "buildingName",
        key: "buildingName",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Floor",
        dataIndex: "floorName",
        key: "floorName",
        ellipsis: true,
        width: 80,
      },
      {
        title: "项目组",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        ellipsis: true,
        width: 100,
      },
      {
        title: "操作",
        dataIndex: "action",
        slots: { customRender: "action" },
        fixed: "right",
        width: 80,
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });

    let roleName = ref()
    const store = useStore();
    roleName.value = store.state.app.defaultRole ? store.state.app.defaultRole : localStorage.getItem("switchRoleName")

    const seatApprovalList = ref();
    let searchCondition = {};

    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
      };
      $api.selectApplicationAdmin(params).then((res) => {
        pagination.total = res.data.total;
        seatApprovalList.value = res.data.resource;
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const handleDetails = (record) => {
      detailData.value = {...record,roleName:roleName.value }
      detailVisible.value = true
    }

    const detailData = ref([])
    const detailVisible = ref(false)
    const closeSaveModal = () => {
      detailVisible.value = false
    }
    const submitDetailModal = () => {
      detailVisible.value = false
      doSearch(searchCondition);
    }
    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });


    return {
      seatApprovalListRef,
      columns,
      pagination,
      seatApprovalList,
      searchClick,
      tableHeight,
      doSearch,
      btnList,
      handleDetails,
      detailVisible,
      closeSaveModal,
      detailData,
      submitDetailModal,
      getSize,
    };
  },
}
</script>

<style scoped lang="less">
.org-list {

  .status{
    font-size: 0.729vw;
    padding: 0.1vw 0.86vw;
    border: 0;

    &.待审批, &.改期待审批 {
      color: #1890FF;
      background: #DBEEFF;
    }
    &.已通过 {
      color: #58B99E;
      background: #DDF8F0 60%;
    }
    &.已过期 {
      color: #999999;
      background: #CCCCCC 60%;
    }
    &.已取消 {
      color: #999999;
      background: #CCCCCC 60%;
    }
    &.已驳回 {
      color: #DC5E4F;
      background: #FFE7EC;
    }

  }

  .userName {
    color: #3182CE;
    background: #E9F6FE;
    margin-left: 0.3vw;
    padding:0 0.2vw
  }
}
</style>
